import { default as _91_46_46_46all_935cbGTG6MG3Meta } from "/opt/build/repo/src/pages/[...all].vue?macro=true";
import { default as assistenzaRwfRuK87X6Meta } from "/opt/build/repo/src/pages/assistenza.vue?macro=true";
import { default as _91id_93xU5R4uiQCcMeta } from "/opt/build/repo/src/pages/blog/[id].vue?macro=true";
import { default as grid4FXM8X8N3rMeta } from "/opt/build/repo/src/pages/blog/grid.vue?macro=true";
import { default as indexIcp49clT2MMeta } from "/opt/build/repo/src/pages/blog/index.vue?macro=true";
import { default as _91id_93eqisxeHVPaMeta } from "/opt/build/repo/src/pages/category/energy/[id].vue?macro=true";
import { default as indexwiN0TZEbNrMeta } from "/opt/build/repo/src/pages/category/energy/index.vue?macro=true";
import { default as _91id_93ZHYz3c9l7QMeta } from "/opt/build/repo/src/pages/category/insurance/[id].vue?macro=true";
import { default as index4Fg6x6bX0IMeta } from "/opt/build/repo/src/pages/category/insurance/index.vue?macro=true";
import { default as _91id_93e4XMWQCXCzMeta } from "/opt/build/repo/src/pages/category/telco/[id].vue?macro=true";
import { default as indexScjgwyX6G8Meta } from "/opt/build/repo/src/pages/category/telco/index.vue?macro=true";
import { default as chi_45siamoBOJWFfAoz3Meta } from "/opt/build/repo/src/pages/chi-siamo.vue?macro=true";
import { default as come_45funziona_45risparmia_45tuiF4owhqGEVMeta } from "/opt/build/repo/src/pages/come-funziona-risparmia-tu.vue?macro=true";
import { default as contattioKbQDmwHrHMeta } from "/opt/build/repo/src/pages/contatti.vue?macro=true";
import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as lavora_45con_45noiNDGsDfH8zuMeta } from "/opt/build/repo/src/pages/lavora-con-noi.vue?macro=true";
import { default as not_45founda7PpRPRJY9Meta } from "/opt/build/repo/src/pages/not-found.vue?macro=true";
import { default as prismic_45previewJDql4TFIg6Meta } from "/opt/build/repo/src/pages/prismic-preview.vue?macro=true";
import { default as privacy_45e_45cookie_45policyyPDZbyIm19Meta } from "/opt/build/repo/src/pages/privacy-e-cookie-policy.vue?macro=true";
import { default as termini_45e_45condizioniuEFAo7SZcwMeta } from "/opt/build/repo/src/pages/termini-e-condizioni.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_935cbGTG6MG3Meta?.name ?? "all",
    path: _91_46_46_46all_935cbGTG6MG3Meta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_935cbGTG6MG3Meta || {},
    alias: _91_46_46_46all_935cbGTG6MG3Meta?.alias || [],
    redirect: _91_46_46_46all_935cbGTG6MG3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: assistenzaRwfRuK87X6Meta?.name ?? "assistenza",
    path: assistenzaRwfRuK87X6Meta?.path ?? "/assistenza",
    meta: assistenzaRwfRuK87X6Meta || {},
    alias: assistenzaRwfRuK87X6Meta?.alias || [],
    redirect: assistenzaRwfRuK87X6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/assistenza.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xU5R4uiQCcMeta?.name ?? "blog-id",
    path: _91id_93xU5R4uiQCcMeta?.path ?? "/blog/:id()",
    meta: _91id_93xU5R4uiQCcMeta || {},
    alias: _91id_93xU5R4uiQCcMeta?.alias || [],
    redirect: _91id_93xU5R4uiQCcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: grid4FXM8X8N3rMeta?.name ?? "blog-grid",
    path: grid4FXM8X8N3rMeta?.path ?? "/blog/grid",
    meta: grid4FXM8X8N3rMeta || {},
    alias: grid4FXM8X8N3rMeta?.alias || [],
    redirect: grid4FXM8X8N3rMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/blog/grid.vue").then(m => m.default || m)
  },
  {
    name: indexIcp49clT2MMeta?.name ?? "blog",
    path: indexIcp49clT2MMeta?.path ?? "/blog",
    meta: indexIcp49clT2MMeta || {},
    alias: indexIcp49clT2MMeta?.alias || [],
    redirect: indexIcp49clT2MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eqisxeHVPaMeta?.name ?? "category-energy-id",
    path: _91id_93eqisxeHVPaMeta?.path ?? "/category/energy/:id()",
    meta: _91id_93eqisxeHVPaMeta || {},
    alias: _91id_93eqisxeHVPaMeta?.alias || [],
    redirect: _91id_93eqisxeHVPaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/energy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwiN0TZEbNrMeta?.name ?? "category-energy",
    path: indexwiN0TZEbNrMeta?.path ?? "/category/energy",
    meta: indexwiN0TZEbNrMeta || {},
    alias: indexwiN0TZEbNrMeta?.alias || [],
    redirect: indexwiN0TZEbNrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/energy/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZHYz3c9l7QMeta?.name ?? "category-insurance-id",
    path: _91id_93ZHYz3c9l7QMeta?.path ?? "/category/insurance/:id()",
    meta: _91id_93ZHYz3c9l7QMeta || {},
    alias: _91id_93ZHYz3c9l7QMeta?.alias || [],
    redirect: _91id_93ZHYz3c9l7QMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/insurance/[id].vue").then(m => m.default || m)
  },
  {
    name: index4Fg6x6bX0IMeta?.name ?? "category-insurance",
    path: index4Fg6x6bX0IMeta?.path ?? "/category/insurance",
    meta: index4Fg6x6bX0IMeta || {},
    alias: index4Fg6x6bX0IMeta?.alias || [],
    redirect: index4Fg6x6bX0IMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93e4XMWQCXCzMeta?.name ?? "category-telco-id",
    path: _91id_93e4XMWQCXCzMeta?.path ?? "/category/telco/:id()",
    meta: _91id_93e4XMWQCXCzMeta || {},
    alias: _91id_93e4XMWQCXCzMeta?.alias || [],
    redirect: _91id_93e4XMWQCXCzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/telco/[id].vue").then(m => m.default || m)
  },
  {
    name: indexScjgwyX6G8Meta?.name ?? "category-telco",
    path: indexScjgwyX6G8Meta?.path ?? "/category/telco",
    meta: indexScjgwyX6G8Meta || {},
    alias: indexScjgwyX6G8Meta?.alias || [],
    redirect: indexScjgwyX6G8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/category/telco/index.vue").then(m => m.default || m)
  },
  {
    name: chi_45siamoBOJWFfAoz3Meta?.name ?? "chi-siamo",
    path: chi_45siamoBOJWFfAoz3Meta?.path ?? "/chi-siamo",
    meta: chi_45siamoBOJWFfAoz3Meta || {},
    alias: chi_45siamoBOJWFfAoz3Meta?.alias || [],
    redirect: chi_45siamoBOJWFfAoz3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/chi-siamo.vue").then(m => m.default || m)
  },
  {
    name: come_45funziona_45risparmia_45tuiF4owhqGEVMeta?.name ?? "come-funziona-risparmia-tu",
    path: come_45funziona_45risparmia_45tuiF4owhqGEVMeta?.path ?? "/come-funziona-risparmia-tu",
    meta: come_45funziona_45risparmia_45tuiF4owhqGEVMeta || {},
    alias: come_45funziona_45risparmia_45tuiF4owhqGEVMeta?.alias || [],
    redirect: come_45funziona_45risparmia_45tuiF4owhqGEVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/come-funziona-risparmia-tu.vue").then(m => m.default || m)
  },
  {
    name: contattioKbQDmwHrHMeta?.name ?? "contatti",
    path: contattioKbQDmwHrHMeta?.path ?? "/contatti",
    meta: contattioKbQDmwHrHMeta || {},
    alias: contattioKbQDmwHrHMeta?.alias || [],
    redirect: contattioKbQDmwHrHMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/contatti.vue").then(m => m.default || m)
  },
  {
    name: indexMUmX8mAfjNMeta?.name ?? "index",
    path: indexMUmX8mAfjNMeta?.path ?? "/",
    meta: indexMUmX8mAfjNMeta || {},
    alias: indexMUmX8mAfjNMeta?.alias || [],
    redirect: indexMUmX8mAfjNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lavora_45con_45noiNDGsDfH8zuMeta?.name ?? "lavora-con-noi",
    path: lavora_45con_45noiNDGsDfH8zuMeta?.path ?? "/lavora-con-noi",
    meta: lavora_45con_45noiNDGsDfH8zuMeta || {},
    alias: lavora_45con_45noiNDGsDfH8zuMeta?.alias || [],
    redirect: lavora_45con_45noiNDGsDfH8zuMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/lavora-con-noi.vue").then(m => m.default || m)
  },
  {
    name: not_45founda7PpRPRJY9Meta?.name ?? "not-found",
    path: not_45founda7PpRPRJY9Meta?.path ?? "/not-found",
    meta: not_45founda7PpRPRJY9Meta || {},
    alias: not_45founda7PpRPRJY9Meta?.alias || [],
    redirect: not_45founda7PpRPRJY9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: prismic_45previewJDql4TFIg6Meta?.name ?? "prismic-preview",
    path: prismic_45previewJDql4TFIg6Meta?.path ?? "/prismic-preview",
    meta: prismic_45previewJDql4TFIg6Meta || {},
    alias: prismic_45previewJDql4TFIg6Meta?.alias || [],
    redirect: prismic_45previewJDql4TFIg6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/prismic-preview.vue").then(m => m.default || m)
  },
  {
    name: privacy_45e_45cookie_45policyyPDZbyIm19Meta?.name ?? "privacy-e-cookie-policy",
    path: privacy_45e_45cookie_45policyyPDZbyIm19Meta?.path ?? "/privacy-e-cookie-policy",
    meta: privacy_45e_45cookie_45policyyPDZbyIm19Meta || {},
    alias: privacy_45e_45cookie_45policyyPDZbyIm19Meta?.alias || [],
    redirect: privacy_45e_45cookie_45policyyPDZbyIm19Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/privacy-e-cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: termini_45e_45condizioniuEFAo7SZcwMeta?.name ?? "termini-e-condizioni",
    path: termini_45e_45condizioniuEFAo7SZcwMeta?.path ?? "/termini-e-condizioni",
    meta: termini_45e_45condizioniuEFAo7SZcwMeta || {},
    alias: termini_45e_45condizioniuEFAo7SZcwMeta?.alias || [],
    redirect: termini_45e_45condizioniuEFAo7SZcwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/termini-e-condizioni.vue").then(m => m.default || m)
  }
]