import revive_payload_client_9bS0b2WAJn from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iYropwVgtu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zQEv6uNCIi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_X62fDU2qNU from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.9.6_vite@5.0.12_@types+node@20.11.5_sass@1.70.0_terser@5.27._qok7c5vbkneowvqw6jjobbseza/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_hD1q9Z3XHw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Sg4pEfKQFz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rfwTZdoDHM from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.6_typescript@5.3.3_vue@3.4.15_typescript@5.3.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_4hpKrKIkO2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_0xpC7Pv3O5 from "/opt/build/repo/node_modules/.pnpm/nuxt-primevue@0.2.2_rollup@4.9.6_vue@3.4.15_typescript@5.3.3_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_client_NinOIW2v0K from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_encoding@0.1.13_rollup@4.9.6_vue@3.4.15_typescript@5.3.3_/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_5rsYlZUUTN from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_encoding@0.1.13_rollup@4.9.6_vue@3.4.15_typescript@5.3.3_/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import plugin_9J2nxtXypE from "/opt/build/repo/node_modules/.pnpm/@ambitiondev+nuxt-cookiebot@3.0.1_rollup@4.9.6_vue@3.4.15_typescript@5.3.3_/node_modules/@ambitiondev/nuxt-cookiebot/dist/runtime/plugin.mjs";
import chunk_reload_client_FTlD0k1MC1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_@parcel+watcher@2.4.0_@types+node@20.11.5_encoding@0.1.13_eslint@8.56.0_optionator_cnrj33pucnqodqw445cihd3dpy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import jquery_client_Q2cvQP2sA4 from "/opt/build/repo/src/plugins/jquery.client.ts";
import gtm_client_Hp0PRXEnoA from "/opt/build/repo/src/plugins/gtm.client.ts";
import trustbox_client_QgjOsT6w7N from "/opt/build/repo/src/plugins/trustbox.client.ts";
export default [
  revive_payload_client_9bS0b2WAJn,
  unhead_iYropwVgtu,
  router_zQEv6uNCIi,
  _0_siteConfig_X62fDU2qNU,
  payload_client_hD1q9Z3XHw,
  check_outdated_build_client_Sg4pEfKQFz,
  plugin_vue3_rfwTZdoDHM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4hpKrKIkO2,
  primevue_plugin_egKpok8Auk,
  plugin_client_0xpC7Pv3O5,
  plugin_client_NinOIW2v0K,
  plugin_5rsYlZUUTN,
  plugin_9J2nxtXypE,
  chunk_reload_client_FTlD0k1MC1,
  jquery_client_Q2cvQP2sA4,
  gtm_client_Hp0PRXEnoA,
  trustbox_client_QgjOsT6w7N
]