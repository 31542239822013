const TRANSITIONS = {
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0'
  }
};

const theme = {
  checkbox: {
    root: {
      class: [
        'cursor-pointer inline-flex relative select-none align-bottom',
        'h-6 w-6'
      ]
    },
    input: ({ context, props }: { context: any, props: any }) => ({
      class: [
        'flex items-center justify-center',
        'border-2 h-full w-full text-gray-600 rounded-lg transition-colors duration-200',
        {
          'border-gray-300 bg-white': !context.checked,
          'border-4 border-primary bg-primary': context.checked
        },
        {
          'hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]': !props.disabled,
          'cursor-default opacity-60': props.disabled
        }
      ]
    }),
    icon: 'w-4 h-4 transition-all duration-200 text-white text-base'
  },
  dialog: {
    root: ({ state }: { state: any }) => ({
      class: [
        'rounded-[30px] shadow-lg border-0',
        'max-h-90 transform scale-100',
        'm-0 w-[50vw]',
        {
          'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized
        }
      ]
    }),
    header: {
      class: ['flex items-center justify-between shrink-0', 'bg-white text-gray-800 border-t-0 rounded-tl-[30px] rounded-tr-[30px] p-8']
    },
    headerTitle: {
      class: 'font-bold text-lg'
    },
    headerIcons: {
      class: 'flex items-center'
    },
    closeButton: {
      class: [
        'flex items-center justify-center overflow-hidden relative',
        'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
        'hover:text-gray-700 hover:border-transparent hover:bg-slate-200',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
      ]
    },
    closeButtonIcon: {
      class: 'w-4 h-4 inline-block'
    },
    content: ({ instance, state }: { instance: any, state: any }) => ({
      class: [
        'overflow-y-visible',
        'bg-white text-gray-700 px-6 pb-6 pt-0',
        {
          grow: state.maximized
        },
        {
          'rounded-bl-lg rounded-br-lg': !instance.$slots.footer
        }
      ]
    }),
    footer: {
      class: ['flex gap-2 shrink-0 justify-end align-center', 'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-[30px]']
    },
    mask: ({ props }: { props: any }) => ({
      class: ['transition duration-200', { 'bg-black/40': props.modal }]
    }),
    transition: ({ props }: { props: any }) => {
      return props.position === 'top'
        ? {
          enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
        }
        : props.position === 'bottom'
          ? {
            enterFromClass: 'opacity-0 scale-75 translate-y-full',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
          }
          : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
            ? {
              enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
            }
            : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
              ? {
                enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
              }
              : {
                enterFromClass: 'opacity-0 scale-75',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75'
              };
    }
  },
  overlaypanel: {
    root: {
      class: [
        'bg-primary text-white border-0 rounded-md shadow-lg',
        'z-40 transform origin-center',
        'absolute left-0 top-0 mt-3',
        'before:absolute before:w-0 before:-top-3 before:h-0 before:border-transparent before:border-solid before:ml-6 before:border-x-[0.75rem] before:border-b-[0.75rem] before:border-t-0 before:border-b-white',
      ]
    },
    content: {
      class: 'p-4 items-center flex shadow-md'
    },
  },
  sidebar: {
    root: ({ props }: { props: any }) => ({
      class: [
        'flex flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300',
        'bg-white text-gray-700 border-0 shadow-lg',
        'h-auto',
        {
          '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': props.position === 'full',
          'h-80 w-80': props.position === 'left' || props.position === 'right',
          'h-40 w-full': props.position === 'top' || props.position === 'bottom'
        },
      ]
    }),
    header: {
      class: ['flex items-center justify-end absolute top-2 inset-x-2']
    },
    closeButton: {
      class: [
        'flex items-center justify-center overflow-hidden relative',
        'w-8 h-8 text-slate-200 hover:text-white z-10',
        'focus:outline-none focus:outline-offset-0',
      ]
    },
    closeButtonIcon: 'w-4 h-4 inline-block',
    content: {
      class: ['mt-10', 'contents', 'w-full', 'grow overflow-y-auto']
    },
    mask: {
      class: ['flex pointer-events-auto', 'bg-black bg-opacity-50 transition duration-200 z-20 transition-colors']
    },
    transition: ({ props }: { props: any }) => props.position === 'top'
      ? {
        enterFromClass: 'translate-x-0 -translate-y-full translate-z-0',
        leaveToClass: 'translate-x-0 -translate-y-full translate-z-0'
      }
      : props.position === 'bottom'
        ? {
          enterFromClass: 'translate-x-0 translate-y-full translate-z-0',
          leaveToClass: 'translate-x-0 translate-y-full translate-z-0'
        }
        : props.position === 'left'
          ? {
            enterFromClass: '-translate-x-full translate-y-0 translate-z-0',
            leaveToClass: '-translate-x-full translate-y-0 translate-z-0'
          }
          : props.position === 'right'
            ? {
              enterFromClass: 'translate-x-full translate-y-0 translate-z-0',
              leaveToClass: 'translate-x-full translate-y-0 translate-z-0'
            }
            : {
              enterFromClass: 'opacity-0',
              enterActiveClass: 'transition-opacity duration-400 ease-in',
              leaveActiveClass: 'transition-opacity duration-400 ease-in',
              leaveToClass: 'opacity-0'
            }
  },
  toast: {
    root: ({ props }: { props: any }) => ({
      class: [
        //Size and Shape
        'w-96 rounded-md',

        // Positioning
        { '-translate-x-2/4': props.position == 'top-center' || props.position == 'bottom-center' }
      ]
    }),
    container: ({ props }: { props: any }) => ({
      class: [
        'my-4 rounded-md w-full',
        'border-solid border-0 border-l-[6px]',
        'backdrop-blur-[10px] shadow-md',

        // Colors
        {
          'bg-blue-100/70 dark:bg-blue-500/20': props.message.severity == 'info',
          'bg-green-100/70 dark:bg-green-500/20': props.message.severity == 'success',
          'bg-orange-100/70 dark:bg-orange-500/20': props.message.severity == 'warn',
          'bg-red-100/70 dark:bg-red-500/20': props.message.severity == 'error'
        },
        {
          'border-blue-500 dark:border-blue-400': props.message.severity == 'info',
          'border-green-500 dark:border-green-400': props.message.severity == 'success',
          'border-orange-500 dark:border-orange-400': props.message.severity == 'warn',
          'border-red-500 dark:border-red-400': props.message.severity == 'error'
        },
        {
          'text-blue-700 dark:text-blue-300': props.message.severity == 'info',
          'text-green-700 dark:text-green-300': props.message.severity == 'success',
          'text-orange-700 dark:text-orange-300': props.message.severity == 'warn',
          'text-red-700 dark:text-red-300': props.message.severity == 'error'
        }
      ]
    }),
    content: {
      class: 'flex items-start p-4'
    },
    icon: {
      class: [
        // Sizing and Spacing
        'w-6 h-6',
        'text-lg leading-none mr-2 shrink-0'
      ]
    },
    text: {
      class: [
        // Font and Text
        'text-base leading-none',
        'ml-2',
        'flex-1'
      ]
    },
    summary: {
      class: 'font-bold block'
    },
    detail: {
      class: 'mt-2 block'
    },
    closebutton: {
      class: [
        // Flexbox
        'flex items-center justify-center',

        // Size
        'w-8 h-8',

        // Spacing and Misc
        'ml-auto  relative',

        // Shape
        'rounded-full',

        // Colors
        'bg-transparent',

        // Transitions
        'transition duration-200 ease-in-out',

        // States
        'hover:bg-surface-0/50 dark:hover:bg-surface-0/10',

        // Misc
        'overflow-hidden'
      ]
    },
    transition: {
      enterFromClass: 'opacity-0 translate-y-2/4',
      enterActiveClass: 'transition-[transform,opacity] duration-300',
      leaveFromClass: 'max-h-[1000px]',
      leaveActiveClass: '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden',
      leaveToClass: 'max-h-0 opacity-0 mb-0'
    }
  },
  directives: {
    tooltip: {
      root: ({ context }: { context: any }) => ({
        class: [
          'absolute',
          {
            'py-0 px-1': context?.right || context?.left || (!context?.right && !context?.left && !context?.top && !context?.bottom),
            'py-1 px-0': context?.top || context?.bottom
          }
        ]
      }),
      arrow: ({ context }: { context: any }) => ({
        class: [
          'absolute w-0 h-0 border-transparent border-solid',
          {
            '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-primary': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
            '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-primary': context?.left,
            '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-primary': context?.top,
            '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-primary': context?.bottom
          }
        ]
      }),
      text: {
        class: 'p-2 bg-primary text-xs text-white rounded-md whitespace-pre-line break-words'
      }
    }
  }
}

export default theme